div.timer {
}

span.timerNum {
    font-family: 'Segoe UI Mono';
    color: #555;
    font-weight: normal;
    font-size: 14pt;
}

span.timerSep {
    font-family: 'Segoe UI';
    color:#999;
    font-weight: normal;
    font-size: 14pt;
}
