div.book {
    -moz-box-shadow: 0 0 16px #ccc;
    -webkit-box-shadow: 0 0 16px #ccc;
    box-shadow: 0 0 16px #ccc;
    border-radius: 24px;
    margin: 8px;
    padding: 8px;
}

img.bookImage {
    height: 192px;
    width: 192px;
    border-radius: 20px;
}

div.bookTitle {
    color: #111;
    font-family: 'Segoe UI Gras';
    font-size: 18px;
}

div.bookPagePersons {
    display: flex;
}

div.bookDictor {
    display: flex;
    align-items: center;
}

div.bookAuthor, div.bookDictor div {
    color: #777;
}

div.bookAuthor { font-size: 16px; }
div.bookDictor span { font-size: 15px; }

div.bookDictor img {
    height: 14px;
    margin-bottom: -2px;
    margin-right: 4px;
}

div.bookName {
    margin-left: 4px;
    margin-right: 4px;
    max-width: 184px;
}

div.bookPageAnnotation {
    margin-top: 16px;
    color: #333;
    max-width: 512px;
}

div.bookPageGenres {
    display: flex;
}

div.bookPageGenre {
    padding-right: 12px;
}