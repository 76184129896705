html {    
    height: 100%;
    padding: 0;
    margin: 0 auto;
    min-width: 1024px;
    max-width: 1600px;
    font-family: 'Segoe UI';     
}

body {
  font-family: 'Segoe UI';
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

div#root, div.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  height: 100%;
}

header {
    height: 75px;
    background-color: #024959;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav {
  display: flex;
  align-items: center;
  font-family: 'Segoe UI Gras';
}

div.user {
  display: flex;
  align-items: center;  
}

img.logo {
  height: 48px;
  display: inline-block;
  margin-right: 16px;
  margin-left: 18px;
}

main {
    flex: 1;
    background-color: #fff;
    overflow-y: auto;
}

footer {
    height: 64px;
    background-color: #024959;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

div.contacts {
  margin-left: 16px;
  flex-direction: column;
  gap: 4px;
}

div.contacts div {
  flex: 1;
}

a.sharoit, div.contacts {
  text-decoration: none;
  color: #ddd;
  display: flex;
  align-items: flex-start;
  padding-right: 16px;
  font-weight: 500;
  flex: 1;
}

div.apps {
  justify-content: center;
  flex: 1;
  display: flex;
}

a.sharoit {
  justify-content: flex-end;
}

div.user > a {
  margin-left: 16px;
  margin-right: 16px;
  text-decoration: none;
  color: #ccc;
  font-weight: 500;
  font-size: 18px;
}


footer > a > img {
  max-height: 52px;
  margin-right: 16px;
}

nav {
  flex: 1;
}

nav > ul {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

nav > ul > li {
  display: inline-block;
  margin: 16px;
  font-weight: 600;
}

nav > ul > li > a {
  color: #ddd;
  text-decoration: none;
  font-size: 22px;
}

h1, h2, h3 {
  font-family: 'Segoe UI Light';
  margin: 8px;
}

h1 {
  color: #444;
  font-size: 32pt;
}

main {
  padding-left: 16px;
  padding-right: 16px;
}

div.search {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

div.searchProgress {
  flex: 1;
  height: 5px;
}