ul.mainMenu {
    display: flexbox;
    margin: 0px;
};

ul.mainMenu li {
    background-color: yellow;
    color: red;
    border: 1px solid red;
}
