div.personsPicks {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    min-height: 256px;
    flex-direction: column;
    align-items: stretch;
}

div.bpHead {
    display: flex;
    align-items: center;
}

div.bpBody {
    display: flex;
}