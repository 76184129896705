div.aboutContainer {
    display: flex;
    flex: 1;
    justify-content: center;
}

h1.entry-title {
    margin-left: 0;
    font-weight: normal;
}

span.published {
    color: darkgray;
}

div.about {
    max-width: 1080px;
    font-size: 20px;
    font-family: 'Segoe UI Light'
}