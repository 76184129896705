div.form {
    -moz-box-shadow: 0 0 16px #ccc;
    -webkit-box-shadow: 0 0 16px #ccc;
    box-shadow: 0 0 16px #ccc;
    border-radius: 32px;
    margin: 0px;
    padding: 24px;
    display: flexbox;
    flex-direction: column;    
}

div.formHeader > h1 {
    flex: 1;
    margin-left: 0px;
    font-size: 22pt;
    margin-top: 0px;
    padding-top: 0px;
    text-align: center;
}

div.formBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

div.fromFooter {
    margin-top: 16px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

div.react-tel-input {
    display: flex;
    flex-direction: column;
}

div.special-label {
    font-size: 13pt;
    color: gray;
}

input.form-control {
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 8px;
    flex: 1;
    font-size: 14pt;
}

button.MuiButton-contained {
    background-color: #00aec7;
    flex: 1;
    width: 100%;
    border-radius: 6px;
}

button.MuiButton-contained:hover {
    background-color: #00aec7;
}

table.profile tbody tr td {
    padding: 8px;
}

div.formInput {
    flex: 1;
    margin: 8px;
    display: flex;
    justify-content: stretch;
}

div.MuiInputBase-root {
    margin-top: 8px;
    margin-bottom: 8px;
}