div.person {
    -moz-box-shadow: 0 0 16px #ccc;
    -webkit-box-shadow: 0 0 16px #ccc;
    box-shadow: 0 0 16px #ccc;
    border-radius: 24px;
    border-top-left-radius: 96px;
    border-top-right-radius: 96px;
    margin: 8px;
    padding: 8px;
    align-items: center;
}

img.personImage {
    height: 192px;
    width: 192px;
    border-radius: 96px;
}

div.personTitle {
    color: #111;
    font-family: 'Segoe UI Gras';
    font-size: 18px;
}

div.personPagePersons {
    display: flex;
}

div.personDictor {
    display: flex;
    align-items: center;
}

div.personAuthor, div.personDictor div {
    color: #777;
}

div.personAuthor { font-size: 16px; }
div.personDictor span { font-size: 15px; }

div.personDictor img {
    height: 14px;
    margin-bottom: -2px;
    margin-right: 4px;
}

div.personName {
    margin-left: 4px;
    margin-right: 4px;
    max-width: 184px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

div.personPageAnnotation {
    margin-top: 16px;
    color: #333;
    max-width: 512px;
}