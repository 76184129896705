div.bookPage {
    flex: 1;
    -moz-box-shadow: 0 0 16px #ccc;
    -webkit-box-shadow: 0 0 16px #ccc;
    box-shadow: 0 0 16px #ccc;
    border-radius: 32px;
    margin: 0px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

div.bookPageDetail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

div.bookPagePersons {
    margin-top: 12px;
}

div.bookPageAuthor {
    margin-top: 6px;
    font-size: 16pt;
    color: #777;
    display: flex;
    align-items: center;
}

div.bookPageAuthor img, div.bookPageDictor img {
    max-height: 42px;
    border-radius: 24px;
    border: 1px solid gray;
    margin-right: 6px;
}

div.bookPageDictor {
    font-size: 14pt;
    margin-top: 6px;
    display: flex;
    align-items: center;
    color: #777;
}

div.bookPageGenre {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 16pt;
    color: #777;
    font-size: 14pt;
}

div.bookPageGenre img {
    max-height: 42px;
    border-radius: 16px;
    border: 1px solid gray;
    margin-right: 6px;
}

div.bookPagePublisher {
    font-family: 'Segoe UI Light';
    margin-top: 16px;
    color: #111;
}