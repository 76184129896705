div.genre {
    -moz-box-shadow: 0 0 16px #ccc;
    -webkit-box-shadow: 0 0 16px #ccc;
    box-shadow: 0 0 16px #ccc;
    border-radius: 24px;
    border-top-left-radius: 64px;
    border-top-right-radius: 64px;
    margin: 8px;
    padding: 8px;
    align-items: center;
}

img.genreImage {
    height: 192px;
    width: 192px;
    border-radius: 64px;
}

div.genreTitle {
    color: #111;
    font-family: 'Segoe UI Gras';
    font-size: 18px;
}

div.genrePagegenres {
    display: flex;
}

div.genreDictor {
    display: flex;
    align-items: center;
}

div.genreAuthor, div.genreDictor div {
    color: #777;
}

div.genreAuthor { font-size: 16px; }
div.genreDictor span { font-size: 15px; }

div.genreDictor img {
    height: 14px;
    margin-bottom: -2px;
    margin-right: 4px;
}

div.genreName {
    margin-left: 4px;
    margin-right: 4px;
    max-width: 184px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

div.genrePageAnnotation {
    margin-top: 16px;
    color: #333;
    max-width: 512px;
}