div.player {
    -moz-box-shadow: 0 0 8px #ccc;
    -webkit-box-shadow: 0 0 8px #ccc;
    box-shadow: 0 0 8px #ccc;
    border-radius: 24px;
    margin: 8px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 320px 320px;
    border: '2px solid green';
    min-height: 640px;
    width: 320px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;    
    margin: 32px;
}

div.playerControlsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
}

div.playerControls {
    display: flex;    
    align-items: center;    
 }

div.playerControls button {
     background-color: transparent;
     border: 0px;
     cursor: pointer;
     padding: 0px;
     margin-left: 8px;
     margin-right: 8px;
}

div.playerControls button:hover {
    filter: drop-shadow(0px 0px 8px rgba(0, 73, 89, 128));
}

div.playerControls button:active {
    filter: drop-shadow(0px 0px 12px rgba(0, 73, 89, 64));
}

div.player1 {
    font-family: 'Segoe UI Mono';
}

div.playerStatusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 12px;
    margin-top: 12px;
}

div.playerPosition {
    display: flex;
    align-items: center;
    padding-left: 8px;
}

button.rateButton {
    font-family: 'Segoe UI Gras';
    border: 1px solid #777;
    color: #777;
    background-color: transparent;
    border-radius: 4px;
    padding-left: 2px;
    padding-right: 2px;
    cursor: pointer;
}

button.rateButton:active {
    background-color: #eee;
}