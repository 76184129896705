div.fragmentsContainer {
    margin-top: 0px;
    display: flex;
    flex: 1;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    max-height: 320px;
}

div.fragmentsHeader {
    display:flex;
    justify-content: space-evenly;
}

button.fragmentsTitle {
    justify-content: center;
    align-items: center;
    border: 0px;
    background-color: transparent;
    border-bottom: 2px solid #ccc;
    flex: 1;
    color: #777;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

button.fragmentsActive {
    color: #333;
    border-bottom: 2px solid #00aec7;
}

div.fragments {
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    flex: 1;
}

div.fragments table tbody tr {
    cursor: pointer;
}

div.fragments table tbody tr:hover {
    background-color: #eee;
}

div.fragments table tbody tr:active {
    background-color: #ddd;
}